<template>
    <li class="nav-item nav-search">

        <!-- Icon -->
        <a href="javascript:void(0)"
           class="nav-link nav-link-search"
           @click="showSearchBar = true">
            <feather-icon icon="SearchIcon"
                          size="21" />
        </a>

        <!-- Input -->
        <div class="search-input"
             :class="{'open': showSearchBar}">
            <div class="search-input-icon">
                <feather-icon icon="SearchIcon" />
            </div>
            <!-- <input type="text" placeholder="Explore Vuexy...." class="form-control-input"> -->
            <!-- @keyup.esc="escPressed" -->
            <!-- @keyup.enter="suggestionSelected" -->
            <b-form-input v-if="showSearchBar"
                          v-model="searchQuery"
                          placeholder="Explore Landingly"
                          autofocus
                          autocomplete="off"
                          @keyup="queryForKeywords"
                          @keyup.esc="showSearchBar = false; resetsearchQuery()"
                          @blur.stop="showSearchBar = false; resetsearchQuery()" />
            <div class="search-input-close"
                 @click="showSearchBar = false; resetsearchQuery()">
                <feather-icon icon="XIcon" />
            </div>

            <!-- Suggestions List -->
            <vue-perfect-scrollbar :settings="perfectScrollbarSettings"
                                   class="search-list search-list-main scroll-area overflow-hidden"
                                   :class="{'show': searchQuery}"
                                   tagname="ul">
                <li v-if="bulkEmails" class="suggestions-groups-list">

                    <!-- Group Header -->
                    <p class="suggestion-group-title">
                        <span>
                            Emails
                        </span>
                    </p>
                    <ul>
                        <li v-for="(item, index) in bulkEmails"
                            :key="index"
                            class="suggestion-group-suggestion cursor-pointer">
                            <template :to="'/addeditemail/'+item.Id">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <p>{{ item.FromName }}</p>
                                        <small>{{ item.Subject }}</small>
                                    </div>

                                </div>
                            </template>
                        </li>
                    </ul>
                </li>

                <li v-if="lstProjects" class="suggestions-groups-list">
                    <p class="suggestion-group-title">
                        <span>Projects</span>
                    </p>
                    <ul>
                        <li v-for="(item, index) in lstProjects"
                            :key="index"
                            class="suggestion-group-suggestion cursor-pointer">
                            <template to="/addeditemail/">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <p>{{ item.Name }}</p>
                                    </div>
                                </div>
                            </template>
                        </li>
                    </ul>
                </li>

                <li v-if="lstContacts" class="suggestions-groups-list">
                    <p class="suggestion-group-title">
                        <span>Contacts</span>
                    </p>
                    <ul>
                        <li v-for="(item, index) in lstContacts"
                            :key="index"
                            class="suggestion-group-suggestion cursor-pointer">
                            <template to="/addeditemail/">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <p>{{ item.FullName }}</p>
                                        <small>{{item.Email}}</small>
                                    </div>
                                </div>
                            </template>
                        </li>
                    </ul>
                </li>

                <li v-if="lstSMTPSettings" class="suggestions-groups-list">
                    <p class="suggestion-group-title">
                        <span>SMTP Settings</span>
                    </p>
                    <ul>
                        <li v-for="(item, index) in lstSMTPSettings"
                            :key="index"
                            class="suggestion-group-suggestion cursor-pointer">
                            <template to="/addeditemail/">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <p>{{ item.FromName }}</p>
                                        <small>{{item.Title}}</small>
                                    </div>
                                </div>
                            </template>
                        </li>
                    </ul>
                </li>

                <li v-if="lstPayPalAccounts" class="suggestions-groups-list">
                    <p class="suggestion-group-title">
                        <span>PayPal Accounts</span>
                    </p>
                    <ul>
                        <li v-for="(item, index) in lstPayPalAccounts"
                            :key="index"
                            class="suggestion-group-suggestion cursor-pointer">
                            <template to="/addeditemail/">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <p>{{ item.AccountName }}</p>
                                    </div>
                                </div>
                            </template>
                        </li>
                    </ul>
                </li>

                <li v-if="lstStripeAccounts" class="suggestions-groups-list">
                    <p class="suggestion-group-title">
                        <span>Stripe Accounts</span>
                    </p>
                    <ul>
                        <li v-for="(item, index) in lstStripeAccounts"
                            :key="index"
                            class="suggestion-group-suggestion cursor-pointer">
                            <template to="/addeditemail/">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <p>{{ item.AccountName }}</p>
                                    </div>
                                </div>
                            </template>
                        </li>
                    </ul>
                </li>

                <li v-if="lstAuthorizeAccounts" class="suggestions-groups-list">
                    <p class="suggestion-group-title">
                        <span>Authorize Net Accounts</span>
                    </p>
                    <ul>
                        <li v-for="(item, index) in lstAuthorizeAccounts"
                            :key="index"
                            class="suggestion-group-suggestion cursor-pointer">
                            <template to="/addeditemail/">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <p>{{ item.AccountName }}</p>
                                    </div>
                                </div>
                            </template>
                        </li>
                    </ul>
                </li>

            </vue-perfect-scrollbar>
        </div>
    </li>
</template>

<script>
    import {
        BFormInput, BLink, BImg, BAvatar,
    } from 'bootstrap-vue'
    import { ref, watch } from '@vue/composition-api'
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import useAutoSuggest from '@core/components/app-auto-suggest/useAutoSuggest'
    import { title } from '@core/utils/filter'
    import router from '@/router'
    import store from '@/store'
    import searchAndBookmarkData from '../search-and-bookmark-data'
    import { $themeConfig } from "@themeConfig";
    import jwt from "@/auth/jwt/useJwt";

    export default {
        components: {
            BFormInput,
            BLink,
            BImg,
            BAvatar,
            VuePerfectScrollbar,
        },
        data() {
            return {
                loading: true,
                variant: "light",
                opacity: 0.85,
                blur: "2px",

                perfectScrollbarSettings: {
                    maxScrollbarLength: 60,
                },

                searchQuery: null,
                filteredData: null,
                showSearchBar: false,

                error: null,
                WebAPIURL: $themeConfig.app.apiURL,

                bulkEmails: null,
                lstProjects: null,
                lstContacts: null,
                lstSMTPSettings: null,
                lstPayPalAccounts: null,
                lstStripeAccounts: null,
                lstAuthorizeAccounts: null,
                
                /*
                 self.lstContacts = response.data.SearchedContacts;
                        self.lstSMTPSettings = response.data.SearchedSMTPSettings;
                        self.lstPalAccounts = response.data.SearchedPayPalAccounts;
                        self.lstStripeAccounts = response.data.SearchedStripeAccounts;
                        self.lstAuthorizeAccounts = response.data.SearchedAuthorizeAccounts;
                 */
            }
        },



        methods: {

            resetsearchQuery(event) {
                this.searchQuery = null;
            },

            queryForKeywords(event) {
                var searchedKeyword = this.searchQuery;
                if (searchedKeyword.length > 2) {
                    this.fetchBulkEmails();
                }

            },

            fetchBulkEmails: async function () {
                this.bulkEmails = null;
                var self = this;
                var axios = require("axios");
                const access_token = await jwt.getToken();

                var qs = require("qs");
                var data = qs.stringify({
                    Keyword: self.searchQuery,
                });

                var headers = {
                    Authorization: "bearer " + access_token,
                };

                axios
                    .get("" + this.WebAPIURL + "/api/Projects/GetSearchedResults?" + data, { headers })
                    .then(function (response) {
                        self.bulkEmails = response.data.SearchedBulkEmails;
                        if (response.data.SearchedProjects.length > 0) {
                            self.lstProjects = response.data.SearchedProjects;
                        }
                        else {
                            self.lstProjects = null;
                        }

                        if (response.data.SearchedContacts.length > 0) {
                            self.lstContacts = response.data.SearchedContacts;
                        }
                        else {
                            self.lstContacts = null;
                        }

                        if (response.data.SearchedSMTPSettings.length > 0) {
                            self.lstSMTPSettings = response.data.SearchedSMTPSettings;
                        }
                        else {
                            self.lstSMTPSettings = null;
                        }

                        if (response.data.SearchedPayPalAccounts.length > 0) {
                            self.lstPayPalAccounts = response.data.SearchedPayPalAccounts;
                        }
                        else {
                            self.lstPayPalAccounts = null;
                        }

                        if (response.data.SearchedStripeAccounts.length > 0) {
                            self.lstStripeAccounts = response.data.SearchedStripeAccounts;
                        }
                        else {
                            self.lstStripeAccounts = null;
                        }

                        if (response.data.SearchedAuthorizeAccounts.length > 0) {
                            self.lstAuthorizeAccounts = response.data.SearchedAuthorizeAccounts;
                        }
                        else {
                            self.lstAuthorizeAccounts = null;
                        }
                    })
                    .catch(function (error) {
                        self.error = error;
                        self.loading = false;
                        console.log("error:: " + error);
                    });
            },

            bulkEmailsFilteredList() {
                return this.bulkEmails.filter((email) => {
                    return this.searchQuery.toLowerCase().split(' ').every(v => email.Subject.toLowerCase().includes(v));
                });
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~@core/scss/base/bootstrap-extended/include';
    @import '~@core/scss/base/components/variables-dark';

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    p {
        margin: 0;
    }

    /* .app-auto-suggest {
      position: relative;
    }

    .auto-suggest-suggestions-list {
      box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
      border-radius: 6px;
      position: absolute;
      top: calc(100% + 1rem);
      background-color: white;
      width: 100%;
    } */

    .suggestion-group-title {
        font-weight: 500;
        padding: .75rem 1rem .25rem;
    }

    .suggestion-group-suggestion {
        padding: .75rem 1rem
    }

    .suggestion-current-selected {
        background-color: $body-bg;

        .dark-layout & {
            background-color: $theme-dark-body-bg;
        }
    }
</style>
