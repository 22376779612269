<template>
    <b-nav-item-dropdown right
                         toggle-class="d-flex align-items-center dropdown-user-link"
                         class="dropdown-user">
        <template #button-content>
            <div class="d-sm-flex d-none user-nav">
                <p class="user-name font-weight-bolder mb-0" id="lblUserName">
                    {{ userData }}
                </p>
            </div>
            <b-avatar size="40"
                      id="navbarimage"
                      :src="profileimage"
                      variant="light-primary"
                      badge
                      class="badge-minimal"
                      badge-variant="success">
                <feather-icon v-if="!userData"
                              icon="UserIcon"
                              size="22" />
            </b-avatar>
        </template>

        <b-dropdown-item :to="{ name: 'pages-profile'}"
                         link-class="d-flex align-items-center">
            <feather-icon size="16"
                          icon="UserIcon"
                          class="mr-50" />
            <span>Profile</span>
        </b-dropdown-item>


        <b-dropdown-divider />

        <b-dropdown-item :to="{ name: 'bonus'}"
                         link-class="d-flex align-items-center">
            <feather-icon size="16"
                          icon="FileIcon"
                          class="mr-50" />
            <span>Bonus</span>
        </b-dropdown-item>


        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center"
                         @click="logout">
            <feather-icon size="16"
                          icon="LogOutIcon"
                          class="mr-50" />
            <span>Logout</span>
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
    import {
        BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
    } from 'bootstrap-vue'
    import { initialAbility } from '@/libs/acl/config'
    import useJwt from '@/auth/jwt/useJwt'
    import { avatarText } from '@core/utils/filter'
    import { $themeConfig } from "@themeConfig";



    export default {
        components: {
            BNavItemDropdown,
            BDropdownItem,
            BDropdownDivider,
            BAvatar,
        },
        data() {
            return {
                userData: JSON.parse(localStorage.getItem('User_Name')),
                avatarText,
                profileimage: null,
                WebAPIURL: $themeConfig.app.apiURL,
                IsEmpirelyUser: false,
                videoUrl: null,
            }
        },
        methods: {
            logout() {

                // Remove userData from localStorage
                // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
                //localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
                //localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
                //localStorage.removeItem(useJwt.jwtConfig.storageTokenExpiryKeyName)
                //localStorage.removeItem(useJwt.jwtConfig.refreshTokenExpirytime)

                //// Remove userData from localStorage
                //localStorage.removeItem('User_Name')
                //localStorage.removeItem('email');

                useJwt.logout();

                // Reset ability
                this.$ability.update(initialAbility)

                // Redirect to login page
               // this.$router.push({ name: 'auth-login' })
            },

            async getUserInfo() {
                var self = this;

                this.loading = true;
                const access_token = await useJwt.getToken();

                var headers = {
                    Authorization: "bearer " + access_token,
                };
                var axios = require("axios");
                axios
                    .get("" + this.WebAPIURL + "/api/ProfileApi/AddEditUserInfo", {
                        headers,
                    })
                    .then((response) => {
                        self.UserInfo = response.data;
                        self.profileimage =
                            "" +
                            this.WebAPIURL +
                            "/Images/ProfilePics/" + response.data.UserId + "/" +
                            response.data.ProfileImage;

                        localStorage.setItem('IsSystemSMTPAllowed', response.data.IsSystemSMTPAllowed);
                        localStorage.setItem('IsEmpirelyUser', response.data.IsEmpirelyUser);

                        this.IsEmpirelyUser = response.data.IsEmpirelyUser;
                        this.videoUrl = this.IsEmpirelyUser == true
                            ? 'https://player.vimeo.com/video/936067683'
                            : 'https://player.vimeo.com/video/871958424';

                        localStorage.setItem('videoUrl', this.videoUrl);

                        self.loading = false;
                    });
            },

        },
        mounted() {

            this.getUserInfo();
        },

    }
</script>
