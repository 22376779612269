<template>
    <div class="navbar-container d-flex content align-items-center">

        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link class="nav-link"
                        @click="toggleVerticalMenuActive">
                    <feather-icon icon="MenuIcon"
                                  size="21" />
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->
        <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
            <b-navbar-nav class="nav" v-if="userRole=='admin'">

                <b-nav-item title="Projects"
                            to="/projects"
                            v-b-tooltip.hover.v-primary
                            id="lstProjects"
                            class="mr-1">
                    <feather-icon icon="PackageIcon" size="21" />
                    <!--<b-tooltip triggers="hover"
                               target="lstProjects"
                               title="Projects"
                               :delay="{ show: 500, hide: 50 }" />-->
                </b-nav-item>

                <b-nav-item id="lstContacts"
                            to="/contacts"
                            title="Contacts"
                            v-b-tooltip.hover.v-primary
                            class="mr-1">
                    <feather-icon icon="UsersIcon" size="21" />
                </b-nav-item>
                <b-nav-item to="sendemails"
                            id="lstEmails"
                            title="Broadcasts"
                            v-b-tooltip.hover.v-primary
                            class="mr-1">
                    <feather-icon icon="MailIcon" size="21" />
                   
                </b-nav-item>

                

            </b-navbar-nav>


        </div>

        <b-navbar-nav class="nav align-items-center ml-auto">
            <!--<locale />-->
            <dark-Toggler class="d-none d-lg-block" />
            <!--<search-bar />-->
            <!--<cart-dropdown />
            <notification-dropdown />-->
            <user-dropdown />
        </b-navbar-nav>
    </div>
</template>
<style>
</style>
<script>
    import {
        BLink, BNavbarNav, BNavItem, BTooltip,
        VBTooltip,
    } from 'bootstrap-vue'
    import Bookmarks from './components/Bookmarks.vue'
    import Locale from './components/Locale.vue'
    import SearchBar from './components/SearchBar.vue'
    import DarkToggler from './components/DarkToggler.vue'
    import CartDropdown from './components/CartDropdown.vue'
    import NotificationDropdown from './components/NotificationDropdown.vue'
    import UserDropdown from './components/UserDropdown.vue'
    import useAppConfig from '@core/app-config/useAppConfig'
    import { computed } from '@vue/composition-api'
    import jwt from "@/auth/jwt/useJwt";

    export default {
        components: {
            BLink,

            // Navbar Components
            BNavbarNav,
            BNavItem,
            BTooltip,
            Bookmarks,
            Locale,
            SearchBar,
            DarkToggler,
            CartDropdown,
            NotificationDropdown,
            UserDropdown,
        },

        directives: {
            'b-tooltip': VBTooltip,
        },
        data() {
            return {
                userRole: jwt.getUserRole(),
            }
        },
        props: {
            toggleVerticalMenuActive: {
                type: Function,
                default: () => { },
            },
        },
        mounted() {
            const { skin } = useAppConfig()
            const isDark = computed(() => skin.value === 'dark')
        }
    }
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

ul
{
  list-style: none;
  padding: 0;
  margin: 0;
}
p {
  margin: 0;
}

.nav-bookmar-content-overlay {
    position: fixed;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-transition: all 0.7s;
    transition: all 0.7s;
    z-index: -1;

    &:not(.show) {
      pointer-events: none;
    }

    &.show {
      cursor: pointer;
      z-index: 10;
      opacity: 1;
    }
}
</style>