<template>
    <b-nav-item title="Change Theme"
                v-b-tooltip.hover.v-primary
                @click="skin = isDark ? 'light' : 'dark'">
        <feather-icon size="21"
                      :icon="`${isDark ? 'Sun' : 'Moon'}Icon`" />
    </b-nav-item>
</template>

<script>
    import useAppConfig from '@core/app-config/useAppConfig'
    import { computed } from '@vue/composition-api'
    import {
        BNavItem,
        BTooltip,
        VBTooltip,
    } from 'bootstrap-vue'

    export default {
        components: {
            BNavItem,
            BTooltip,
            VBTooltip,
        },
        directives: {
            'b-tooltip': VBTooltip,
        },
        setup() {
            const { skin } = useAppConfig()

            const isDark = computed(() => skin.value === 'dark')

            return { skin, isDark }
        },
    }
</script>
