export default [
    //{
    //  header: 'Dashboards',
    //  icon: 'HomeIcon',
    //  children: [
    //    {
    //      title: 'eCommerce',
    //      route: 'dashboard-ecommerce',
    //      icon: 'ShoppingCartIcon',
    //    },
    //    {
    //      title: 'Analytics',
    //      route: 'dashboard-analytics',
    //      icon: 'ActivityIcon',
    //    },
    //  ],
    //  },
    {
        title: 'Dashboard',
        route: 'dashboard',
        icon: 'HomeIcon',
    },

    {
        title: 'Projects',
        route: 'projects',
        icon: 'PackageIcon',
    },

    {
        title: 'Contacts',
        route: 'contacts',

        icon: 'UsersIcon',
    },

    {
        title: 'Broadcasts',
        route: 'sendemails',
        icon: 'MailIcon',
    },

    {
        title: 'SMTP',
        route: 'smtpconfigurations',
        icon: 'SettingsIcon',
    },

    {
        title: 'Payments',
        route: 'paymentmethods',
        icon: 'CreditCardIcon',
    },
    
    {
        title: 'Orders',
        route: 'orders',
        icon: 'ShoppingCartIcon',
    },


    {
        title: 'Training',
        route: 'training',
        icon: 'TvIcon',
    },

    {
        title: 'Support',
        route: 'support',
        icon: 'HelpCircleIcon',
    },
]
