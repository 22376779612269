/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/

//import jwt from "@/auth/jwt/useJwt";
import dashboard from './dashboard'
//import admindashboard from './admindashboard'
//import others from './others'

//import appsAndPages from './apps-and-pages'
//import chartsAndMaps from './charts-and-maps'
//import uiElements from './ui-elements'
//import formAndTable from './forms-and-table'

// Array of sections
//export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]

/*const user_role = jwt.getUserRole();*/
//console.log("user_role :: " + user_role);

//const navigationArr = user_role == "admin" ? [...dashboard] : [...admindashboard]
//export default navigationArr
export default [...dashboard]